
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600&display=swap');

@font-face {
font-family: 'Archia';
font-style: normal;
font-weight: normal;
src: local('Archia Regular'), url('/assets/fonts/Archia-Regular.woff') format('woff');
}

$offblack: #171717;
$cream: #F3F3EF;


:root {
  font-size: 20px;
 
    --color-cursor: 220, 90, 90;
    --cursor-outline-shade: 0.3;
    --cursor-size: 20px;
    --cursor-outline-size: 26px;
}


html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

html,
body,
#root {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
 // overflow: hidden;
  cursor: none;
}

body {
  font-family: 'Jost', -apple-system, system-ui, sans-serif;
  font-size: 1.125em;
  font-weight: 400;
  line-height: 150%;
  background: $cream;
  color: $offblack;
}

canvas{
  margin-top: 80px;
}

h1 {
  
  font-style: normal;
  font-weight: 900;
  color: $offblack;
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 86px;
  line-height: 1em;
  letter-spacing: -4px;
  letter-spacing: 2.25px;
  text-align: center;
}

h2 {
  
  font-style: normal;
  font-weight: 900;

  font-size: 62px;
  line-height: 62px;
  letter-spacing: -2px;
  margin-top: 0;
  margin-bottom: 3rem;
  letter-spacing: 2.25px;

  text-align: center;
}

h3 {
  font-style: normal;
  font-weight: 600;

  font-size: 20px;
  line-height: 20px;
  margin-top: 2rem;
  margin-bottom: 0;
}

p {
  font-size: 1.125em;
  font-weight: 400;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 1rem;
  margin-bottom: 0;
  -webkit-font-smoothing: antialiased;
}

a{
  font-weight: 400;
/*  color: #171717; */
  color: #336402;
  text-decoration: none;
  line-height:26px;
}

a.underline{
  position: relative;
  margin: 0 4px 0;
  overflow: hidden;
  display: block;
  transition: all .3s ease-out;
  letter-spacing: 1px;
}

a.underline:hover{
  color: #fff;
}

a.underline:before{
  content:'';
  width: 100%;
  transform: translateX(0%);
  height: 15px;
  background-color: #336402;
  position: absolute;
  top: 24px;
  left: 0;
  z-index: -1;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0.2,1,0.3,1);
  transition-timing-function: cubic-bezier(0.2,1,0.3,1);
}

a.underline:hover::before{
  transform: translate3d(300%,0,0) scale3d(0,1,1);
}

a.underline:after{
  content:'';
  width: 106%;
  transform: translateX(-3%);
  height: 15px;
  background-color:rgb(245, 109, 30);
  position: absolute;
  top: 24px;
  left: 0;
  z-index: -1;
  transform: translate3d(-300%,0,0) scale3d(0,1,1);
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0.2,1,0.3,1);
  transition-timing-function: cubic-bezier(0.2,1,0.3,1);
  transition-delay: .1s;
}

a.underline:hover::after{
  transform: translate3d(0%,0,0) scale3d(1,1,1);
}



.menubar{
  position: fixed;
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  padding: 0px 40px 0;
  font-size: 16px;
  letter-spacing: 2px;
  margin-top:  0px;
  z-index:1;
  background-color: $cream;
 

  a{
    margin-left:30px;
    color: $offblack;
    transition: color .3s ease-out;
   // cursor: pointer;

    &:hover{
      color: #EEC965;
    }
  }
/*
  a > span{
    color: $offblack;
  }
*/


  .logo-title{
    font-size: 16px;
    font-weight: 400;
  //  cursor: pointer;
    a{
      line-height: 1em;
      margin: 0;
    }
  }

  .menu.left{
    position: relative;
    margin-left: 0px;
    opacity:0;
    transition: all .4s ease-out;
    margin-top: 20px;

    

      &::before{
        content: '';
        position: absolute;
        left: -45px;
        top: -20px;
        width: 40px;
        height: 40px;
      //  background-image: url(/static/media/whitepine-logo.c36bc553.svg);
        background-repeat: no-repeat;
      }
  }

  .menu.left.showlogo{

      opacity:1;
    
  }
}

.main-logo{
  height: 50vh;
}

.page {
  padding: 0 15rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.page.right {
  justify-content: flex-end;
  align-items: flex-end;
}

.page .block::before {
}

.half {
  width: 450px;
  max-width: 50%;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 4rem;
  grid-template-areas: 'left right' 'content content';
}

.content {
  grid-area: content;
  position: relative;
  overflow: hidden;
}

.left {
  grid-area: left;
}

.right {
  grid-area: right;
}

.grid{
  margin: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.imgbox{
  width: 50%;
}
.textbox{
  width: 50%;
  padding: 0 20px;
}

@keyframes changewidth {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.transition {
  display: inline-block;
  position: absolute !important;
  transition: all 0.5s ease;
  transition-property: opacity, transform;
}

.transition.vertical[hidden] {
  transition-delay: 0s;
  opacity: 0;
  transform: translate3d(0, -20px, 0) !important;
}

.transition.horizontal[hidden] {
  transition-delay: 0s;
  opacity: 0;
  transform: translate3d(30px, 0, 0) !important;
}
/*
.logo {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 100px;
  width: 60px;
  height: 100px;
  background-image: url('/awv.png');
  background-size: center;
  background-repeat: no-repeat;
  background-size: contain;
}
*/
.menu{
 // color: #F4FF90;
  text-transform: uppercase;
 // position: fixed;
}

.menu.left {
  display:block;
}

.menu.right {
  display:block;
}


.menu.middle {
  position: absolute;
  top: 4rem;
  left: 4rem;
  right: 4rem;
  display: flex;
  justify-content: center;
}

.menu > span {
  padding-left: 1rem;
  padding-right: 1rem;
}

.menu > span:not(:last-child) {
 // border-right: 1px solid rgba(0, 0, 0, 0.5);
}

.container{
  margin: 0 auto;

  width: 100vw;
 // max-width: 1040px;
  border: 1px solid yellow;
  position: relative;
}

.path-holder{
  position: absolute;
  top: 0;
  left: 0;
  width: 75vw;
  //height: 400px;
 // border: 2px solid pink;
  overflow: hidden;
  transition-property: height;
  transition-duration: .4s;
  top: 50vh;
    height: 100%;
    left: 20%;
}

.grid-holder{
  width: 100vw;
  height: 100%;
  position: absolute;
  padding: 100px;
  top: 0;
  left:0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;

  .column{
   
    border-right: 1px solid #8080801f;
    width: 25%;
    height: 100%;
  }

  .column.first{
    border-left: 1px solid #8080801f;
  }

}



.wide-panel{
  width:100vw;
 // min-height: 100vh;
//  margin: 40px;
  border: blue;
  position: relative;
  min-height: 300px;
}

.all-text{
  padding: 0 40px 20px;

  p{
    font-size: 20px;
  }
}

.short-hero{

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  margin-top: 80px;

  h2{
    font-size: 28px;
    font-weight: 400;
    text-align: left;
    margin: 40px;
    line-height: 32px;
  }
}


.hero {
  /*
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -40%, 0);
  */
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
 // min-width: 380px;
  width: 100%;
  height: 100vh;
  padding-left: 10px;
  //background-color: rgba(150,50,180,.3);
  position: relative;

  &::before{
    content:"";
    top: 0;
    left: 40px;
    width: 100vw;
    height: 100%;
    background-image: url('assets/topo-test.png');
    background-size: cover;
    position: absolute;
    opacity: .1;
    -webkit-animation: slide 40s linear infinite;
  }

  

  .logo-holder{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position:relative;
    

    &::before{
      content:'';
      position:absolute;
      left:-90px;
      top:-40px;
      width:80px;
      height:80px;
     // border:1px solid red;
      background-image: url('assets/whitepine-logo.svg');
      background-repeat: no-repeat;

    }

    .logo-main{
      max-width: 100px;
      width:40%;
    }

    .logo-title{
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 400;
    }
  }
  


  .main-headline{
    font-size: 48px;
    text-transform: uppercase;
    letter-spacing: 8px;
    margin: 24px 0;
  }



}

.hero .grid{
  padding: 100px;
  flex-direction: column;
  align-items: baseline;
  position: relative;

  a{
    border-bottom: 3px solid #FFE35F;
    color: $offblack;
    transition: color .3s ease-out;
    padding: 3px 6px;
  }
  a:hover{
    color: #EEC965;
    background-color: white;
    border-color: #EEC965;
  }
}

.hero h1 {
  //width: 0;
  text-align: left;
}

.hero p {
  line-height: 200%;
  color: rgba(0, 0, 0, 0.75);
  text-align: left;
  margin-bottom: 1.5rem;
}
/*
.hero p > span {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.hero p > a {
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  background: #fcfabb;
  color: #272730;
  padding: 4px 10px;
  margin-right: 4px;
  border-radius: 5px;
  cursor: pointer;
  text-align: left;
  vertical-align: bottom;
  top: 3px;
}

.hero button {
  width: 240px;
  border: none;
  border-radius: 5px;
  padding: 1rem 2rem;
  margin: 0;
  margin-top: 1rem;
  text-decoration: none;
  background: black;
  color: white;
  font-family: Varela;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;
  cursor: pointer;
  text-align: center;
}
*/

.trigrid{
  position: absolute;
  top: -200px;
  left: 25%;
  will-change: transform;
  z-index: -1;
  

  min-height: 100vh;
 /* width: calc(100% - var(--grid-size) * 2);*/
  width: 100vw;
  height: 100vh;
  
  display: grid;
  --grid-size: calc(7rem + 3vw);
  --grid-size-y: calc(var(--grid-size) * 0.8660254);
  --grid-column-gap-mult: 0.15;
  --num-cols: 4;
  grid-auto-rows: var(--grid-size-y);
  grid-template-columns: repeat(var(--num-cols), var(--grid-size));
 // grid-row-gap: var(--grid-size-y);
  grid-row-gap: 180px;
  grid-column-gap: calc(var(--grid-size) * var(--grid-column-gap-mult));
  -webkit-box-pack: center;
          justify-content: center;
  font-size: calc(var(--grid-size) * 0.15);

  
}

.triangle {
  position: relative;
  //background-color: #d8ddcc45; 
 // background-color:#d8ddcc78;
  background-color:#9aa97678;
  /*background-color: #F3F3EF;*/
  background-size: 100% 100%;
  background-position: center;
  transform: scale(2);
  
  
/*  -webkit-transform: scale(1.8);
          transform: scale(1.8);
          */
 /* opacity: .5; */
/*
  animation-name: animateIn;
    animation-duration: 1s;
    animation-delay: calc(var(--animation-order) * 250ms);
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
    */
  /*
    &:nth-child(6n):before {
      position: absolute;
      top: calc(50% + 1em);
      left: 50%;
      transform: translate(-50%, -50%) translate(0, -0.3em);
      content: "awesome";
      font-family: "Kaushan Script", cursive;
      color: #e26;
    }
  
    &:nth-child(18n):before {
      transform: translate(-50%, -50%) rotate(-60deg) translate(0, -0.3em);
      content: "radical";
    }
  
    &:nth-child(18n + 6):before {
      transform: translate(-50%, -50%) rotate(60deg) translate(0, -0.3em);
      content: "tubular";
    }
  */
}
.triangle:nth-child(odd) {
  -webkit-clip-path: polygon(0 0, 100% 0, 50% 98%, 0 0);
          clip-path: polygon(0 0, 100% 0, 50% 98%, 0 0);
}
.triangle:nth-child(even) {
  -webkit-clip-path: polygon(50% 2%, 100% 100%, 0 100%, 50% 2%);
          clip-path: polygon(50% 2%, 100% 100%, 0 100%, 50% 2%);
}
.triangle:nth-child(n+5) {
  transform: scale(2) translateX(58%);
}
.triangle:nth-child(n+9) {
  transform: scale(2) translateX(116%);
}
.triangle:nth-child(24n):before {
 // color: #4cd;
}
.triangle:nth-child(12n-3) {
 // background-image: none;
 // background-color: #ddd4cc47
}
.triangle:nth-child(5n-3) {
 /* background-color: #F3F3EF;*/
}


.tricursor{
  position: relative;
  z-index: 99;
 // border-color: transparent transparent #fff transparent;
//  border-style: solid;
 //   border-width: 0 25px 40px 25px;

    &::before{
      content:'';
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      border-color: transparent transparent #000 transparent;
      border-style: solid;
      border-width: 0 12px 26px 12px;
      margin-left: -13px;
      margin-top: -6px;

  
        filter: blur(6px);
        z-index: -1;
        opacity:.5;
  
    }

  &::after{
    content:'';
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    border-color: transparent transparent #fff transparent;
    border-style: solid;
    border-width: 0 12px 26px 12px;
    margin-left: -13px;
    margin-top: -6px;
    transition: all .3s ease-out;
    transform: scale(1)

 

  }
}

.tricursor.goblack{

  &::before{
   // opacity:0;
    border-color: transparent transparent #FFE35F transparent;
  }
  &::after{
    //EEC965;#FFE35F
    border-color: transparent transparent #000 transparent;
    transform: scale(.8)
  }
}

.intro{

  .grid{
    padding: 100px;
    
  }

  h3{
    margin: 0;
    line-height: 46px;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 1px;
  }

}

.about{
  .grid{
   // padding: 100px;
    align-items: center;
  }

  .founder{
    width: 100%;
   // max-width: 450px;
    margin-top: 25px;
  }

  h2{
   // text-align: left;
   // margin-bottom: 30px;
   // font-size: 42px;
    text-align: left;
    font-size: 24px;
    line-height: 32px;
  }

  p{
    font-family: 'Archia',  -apple-system, system-ui, sans-serif;
    font-size: 16px;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.8);
  }
}

.bottom-bkgd{
  bottom: 0;
    left: 0px;
    width: 100vw;
    height: 32vw;

    position: absolute;
  
    z-index: -1;

    &::before{
      content:'';
     top:0;
      left: 0px;
      width: 100%;
      height: 100%;
      background-image: url('assets/topo-test.png');
      background-size: cover;
      position: absolute;
      opacity: .1;

    }
}


.footer{
  width: 100%;
  height: 45vh;
  padding: 40px;
  min-height: 300px;
  max-height: 360px;

  .trigrid{
    height: 100%;
    min-height: 200px;
    left: -40%;
    transform: scale(-1);
    top: -30%;
    z-index: -1;

    .triangle{
      position: relative;

      &::before{
        content:'';
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height:100%;
        background-image: url('assets/topo-test.png');
        background-size: 140%;
        opacity: 0.3;
      }
    } 
  }

  &::before{
    content:'';
    position:absolute;
    left:50px;
    top:50px;
    width:52px;
    height:50px;
    transform: scale(.8);
    height: 50px;
    opacity: .6;
   // border:1px solid red;
    background-image: url('assets/whitepine-logo-offgreen.svg');
    background-repeat: no-repeat;

  }

  .grid{
    height: 100%;
    background-color: #3E3D3B;
    margin:0;

    .footer-links{
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        h3{
          color: #EEC965;
          font-weight: 400;
          letter-spacing: 1px;
          font-size: 22px;
          margin-top: 0;
        }

        a.underline{
          color: white;
          font-weight: 300;
          font-size: 18px;
          order:3;
          transform: translateY(-5px);

          &:hover{
            color: white;
          }

          &::after{
            background-color: #fff;
          }
        }

        &::after{
          content: ' ';
          border-bottom: 2px solid #EEC965;
          flex-grow: 1;
          order: 2;
          margin: 5px 10px;
          transform: translateY(7px);
        }
     
        
        a.underline:before{
          content:'';
          width: 100%;
          transform: translateX(0%);
          height: 15px;
          background-color: #EEC965;
        }
      }
  }
}


.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: inherit;
  //overflow: auto;
  overflow-y: auto;
//  border: 2px solid green;
  overflow-x: hidden;
}



@media (max-width: 1025px) {

  .menubar{
    font-size:14px;
    padding: 0 20px;
    align-items: center;
    justify-content: center;
  }

  .hero .grid{
    padding: 0 0 0 65px;

    .logo-title{
      font-size: 28px;
    }
  }

  .intro .grid{
    padding: 50px 30px;

    h3{
      line-height: 42px;
      font-size: 22px;
    }
  }

  .about .grid{
    padding: 0;
    
  }

  .footer{
    padding: 20px;
   .grid .footer-links{
     width: 100%;
     margin: 30px;
     

     &::after{
       display:none;
     }

     a.underline{
      transform: translateY(2px);
     }
   }

  }
}

@media (max-width: 900px) {
  .about{
    .grid{
      flex-direction: column;
      padding: 10px;
      align-items: flex-start;

    }

    h2{
      margin: 60px 0 0;
    }

    .imgbox{
      width: 100%;
    }

    .textbox{
      width: 100%;
    }
  }

  .menu.right{
    display: none;
  }
}

@media (max-width: 580px) {
  .tricursor{
    display: none;
  }

  .about .founder{
    max-width: initial;
    margin-top: 0px;
  }

  .trigrid{
    transform: scale(.5) translate(-70%, 40%);
    left: 0;
  }

  .grid-holder{
    
    padding: 30px;
    padding-top: 60px;
    opacity: .5;
  }

  .menubar{
    position: relative;
    height: auto;

    .menu.left{
      position: relative;
     // margin: 0;
      width: 100vw;
      opacity:1;

      &::before{
        left: 50%;
        transform: translateX(-50%);
     
        width: 90px;
        height: 90px;
        top: 15px;
      }

      .logo-title{
       // display:none;
      }
    }
    .menu.right{
      display: none;
    }
  }

  .grid{
    margin:10px;
  }

  .hero{
    padding: 0;
    padding-top: 160px;
    justify-content: flex-start;

    &::before{
      top: 60px;
      left:30px;
    }
   

    .grid{
      padding: 0px;
     // border: 1px solid green;
      margin-bottom: 40px;
      align-items: center;
      

      .logo-holder{
        &::before{
          display: none;
        }
      }

      .logo-title{
        margin: 0;
        text-align: center;
        line-height: 30px;
        letter-spacing: 2.5px;
        font-weight: 400;
        font-size: 1em;

        
      }

      .main-headline {
        font-size: 28px;
        line-height: 38px;
        margin: 44px 0;
        text-align:center;
      }

      a{
        text-align: center;
        margin: 0 auto;
      }
    }
  }

  .notmobile{
    opacity: 1 !important;
     color: blue; 
     animation: none;
  }

  .intro{
    height: auto;
    margin: 30px 0;
   
    
    .grid{
      padding: 10px;
    }

    h3{
      font-size: 18px;
      line-height: 30px;
    }
  }

  .about{
    .grid{
      flex-direction: column;
      padding: 10px;
      align-items: flex-start;

    }

    .imgbox,
    .textbox{
      width: 100%;
    }
    .textbox{
      margin-bottom: 80px;
    }

    h2{
      text-align: center;
    }
  }

  .footer{
    padding:20px;

    &::before{

      left:30px;
      top:30px;
    }

    .grid{
      padding:10px;
      min-height: 300px;
      margin-bottom: 30px;
    }
    .footer-links{
      width: 80%;
      display: block !important;

      h3{
        margin: 20px 0 !important;
        padding-top: 20px;
      }
    }
  }

  .logo {
    width: 70px;
    height: 70px;
    top: 1.5rem;
    left: 2rem;
  }
  .menu.right {
    top: 2rem;
    right: 2rem;
  }
  .hero h1 {
    font-size: 46px;
    line-height: 46px;
    letter-spacing: 26px;
    margin-left: 26px;
  }
}



@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 60px, 0);
            transform: translate3d(0, 60px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 60px, 0);
            transform: translate3d(0, 60px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
}

@-webkit-keyframes slide {
  0% { transform: translate(0,-40px); }
  25% { transform: translate(-25px,0px); }
  50% { transform: translate(0px,40px); }
  75% { transform: translate(25px,0px); }
 // 80% { transform: translate(25px,30px); }
  100% { transform: translate(0,-40px); }

  

}

#cursor-dot,
#cursor-dot-outline {
  z-index: 999;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 60px 103.9px 60px;
  border-color: transparent transparent #ffffff transparent;
 /* border-radius: 50%; */
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
}

#cursor-dot {
  width: var(--cursor-size);
  height: var(--cursor-size);
  background-color: rgba(var(--color-cursor), 1);
}

#cursor-dot-outline {
  width: var(--cursor-outline-size);
  height: var(--cursor-outline-size);
  background-color: rgba(var(--color-cursor), var(--cursor-outline-shade));
}
